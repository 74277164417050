import './App.css';
import { ethers } from 'ethers';
import axios from 'axios';
import moment from 'moment/moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//abis
import stakingABI from "./abis/staking.json"
import nftABI from "./abis/nft.json"
//images
import logo from "./images/logo.png"
import gif from "./images/nft.gif"

import { useEffect, useState } from 'react';


function App() {
  const rpcProvider = process.env.REACT_APP_RPC_PROVIDER 
  useEffect(() => {
    getInfo()
    const interval = setInterval(() => {
      getInfo()
    }, 30000);
    return () => clearInterval(interval);
  }, []);
  //useStates
  const [cwText, setcwText] = useState("Connect Wallet")
  const [isConnected, setisConnected] = useState(false)
  const [totalStaked, settotalStaked] = useState(0)
  const [claimableRewards, setclaimableRewards] = useState(0)
  const [userStaked, setuserStaked] = useState([])
  const [userNFTs, setuserNFTs] = useState(0)
  const [userROI, setuserROI] = useState(0)
  const [sbonkPrice, setsbonkPrice] = useState(0)
  let [userAddress, setuserAddress] = useState(0)
  //addresses
  const stakingAddress = "0x3FFdB968B20c95e3f7f33182482903Ed37F16e54"
  const nftAddress = "0xA71EB5d6F1fc277bF63Bad45E45323Ee5ceB51d9"
  //functions
  //getInfo function, gets general info
  async function getInfo(){
    const provider = new ethers.JsonRpcProvider(rpcProvider)
    const nftContract = new ethers.Contract(nftAddress, nftABI, provider)
    const totalStakedBN = await nftContract.balanceOf(stakingAddress)
    const totalStaked = ethers.formatUnits(totalStakedBN,0)
    settotalStaked(totalStaked)
    const SBONKprice = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=shibonk-311f81df-a4ea-4f31-9e61-df0af8211bd7&vs_currencies=USD")
    setsbonkPrice(Object.values(SBONKprice.data)[0].usd)
  }
  //getUserInfo function, gets specific info about the connected user
  async function getUserInfo(){
    const provider = new ethers.BrowserProvider(window.ethereum)
    const nftContract = new ethers.Contract(nftAddress, nftABI, provider)
    const stakingContract = new ethers.Contract(stakingAddress, stakingABI, provider)
    let account
    await provider.send("eth_requestAccounts",[]).then(
      (res) => {
        account = res[0]
      })
    //userstaked
    stakingContract.userStakeInfo(account).then(
      (res) => {
        const userStakedTokens = Object.values(res[0])
        if(userStakedTokens.length > 0){
          setuserStaked(userStakedTokens)
        }else{
          setuserStaked([])
        }
      //userclaimable
      const userClaimable = ethers.formatUnits(res[1], 9)
      setclaimableRewards(userClaimable)
      //userROI
      if(userStakedTokens.length > 0){
        setuserROI((userStakedTokens.length/totalStaked)*24*6780000000*sbonkPrice*(1E-9))
      }else{
        setuserROI("No NFTs Staked")
      }
      }
    )
    //usernfts
    const userNFTsBN = await nftContract.balanceOf(account)
    setuserNFTs(ethers.formatUnits(userNFTsBN,0))
  }
  //connect function
  async function connect(){
    try{
      const provider = new ethers.BrowserProvider(window.ethereum)
      const chainId = await provider.getNetwork()
      if(chainId.chainId !== 56n){
        alert("Please connect using Binance Smart Chain network!")
        window.location.reload()
        return
      }
      await provider.send("eth_requestAccounts", []).then(
      (res) => {
      const account = res[0]
      setcwText("0x..." + account.slice(37,42))
      setuserAddress(account)
      setisConnected(true)
      getInfo()
      getUserInfo()
      toast.success("Connected Successfully!",{
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: false,
        progress: undefined,
        theme: "dark",
      })
      })
    }catch(ex){
      alert(ex)
    }
  }
  //stake function
  async function stake(){
    const provider = new ethers.BrowserProvider(window.ethereum)
    const signer = await provider.getSigner()
    const stakingContract = new ethers.Contract(stakingAddress, stakingABI, signer)
    const nftContract = new ethers.Contract(nftAddress, nftABI, signer)
    const userTokens = await nftContract.getUserNfts(userAddress)
    try{
      const approve = await nftContract.setApprovalForAll(stakingAddress, true)
      await approve.wait()
      const stake = await stakingContract.stake(Object.values(userTokens))
      await stake.wait()
      getInfo()
      getUserInfo()
      toast.success("You have successfully staked your KingsFund NFT!",{
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: false,
        progress: undefined,
        theme: "dark",
      })
    }catch(ex){
      alert(ex)
    }
  }
  //unstake function
  async function unstake(){
    const provider = new ethers.BrowserProvider(window.ethereum)
    const signer = await provider.getSigner()
    const stakingContract = new ethers.Contract(stakingAddress, stakingABI, signer)
    const tokensToUnstake = userStaked
    try{
      const unstake = await stakingContract.withdraw(Object.values(tokensToUnstake))
      await unstake.wait()
      getInfo()
      getUserInfo()
      toast.success("You have successfully unstaked your KingsFund NFT!",{
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: false,
        progress: undefined,
        theme: "dark",
      })
    }catch(ex){
      alert(ex)
      console.log(ex)
    }
  }
  //claimRewards function
  async function claim(){
    const provider = new ethers.BrowserProvider(window.ethereum)
    const signer = await provider.getSigner()
    const stakingContract = new ethers.Contract(stakingAddress, stakingABI, signer)
    try{
      const claim = await stakingContract.claimRewards()
      await claim.wait()
      getInfo()
      getUserInfo()
      toast.success("You have successfully claimed your rewards!",{
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: false,
        progress: undefined,
        theme: "dark",
      })
    }catch(ex){
      alert(ex)
    }
  }

  //XHTML - frontend
  return (
    <div className="App">
      <div id='Header'>
        <a href='https://kingsfund.io/'>
          <img src={logo} alt="logo" id="Logo"/>
        </a>
        <button onClick={connect}>{cwText}</button>
      </div>
      <ToastContainer></ToastContainer>
      <div id='Cards'>
        <div id='TopCard'>
        <img src={gif} alt="nft" id="NFT"/>
        <div style={{display:"flex", gap:"5px",alignItems:"center"}}>
        <span>Pool Rewards: </span>
        <a href='https://coinmarketcap.com/currencies/shibonk-/'>
        <img src='https://assets.coingecko.com/coins/images/28675/small/bonklogo2.png?1673161859' alt='sbonk' style={{width:"30px"}}/>
        </a>
        </div>
        
        <span>$SBONK Price: {sbonkPrice}</span>
        <span>APR: {Number.parseFloat(((59397*sbonkPrice)/(500*totalStaked))*100).toFixed(2) + "%"}</span>
        <span>Total staked: {totalStaked}</span>
        <span>Days Before Pool Ends: {Math.floor(moment.duration(Date.UTC(2023,4,14) - Date.now()).asDays())}</span>
        {
          isConnected ? 
          <>
          <button onClick={stake}>Stake All</button>
          <button onClick={unstake}>Unstake All</button>
          <button onClick={claim}>Claim Rewards</button>
          <span>My NFTs: {userNFTs}</span>
          <span>My Staked NFTs: {userStaked.length}</span>
          <span>My ROI/DAY: {Number.parseFloat(userROI).toFixed(2) === "NaN" ? 0 : "$" + Number.parseFloat(userROI).toFixed(2)} </span>
          <span>My Potential ROI: {Number.parseFloat(userROI).toFixed(2) === "NaN" ? "0" : "$" + Number.parseFloat(Math.floor(moment.duration(Date.UTC(2023,4,14) - Date.now()).asDays())*(userROI)).toFixed(2)} </span>
          <span>My Claimable Rewards: {Number.parseFloat(claimableRewards).toFixed(4)} <a href='https://bscscan.com/address/0x83f888F5B961b369C5eeC236372e5644153EF3c5'>$SBONK</a></span>
          </>
          : <></>
        }
        </div>
        <div id='TopCard'>
          <button onClick={() => window.open("https://market-kingsfund.io/")}>Mint KingsFund NFT</button>
          <div id='Socials'>
          <button onClick={() => window.open("https://kingsfund.io")}>Website</button>
          <button onClick={() => window.open("https://twitter.com/kings_fund")}>Twitter</button>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default App;